<template>
  <v-snackbar
    v-model="snackbar"
    :color="color"
    :timeout="4000"
    top
  >
    <slot />
    <v-btn
      text
      @click="snackbar = false"
    >
      Close
    </v-btn>
  </v-snackbar>
</template>
<script type="text/javascript">
export default {
  name: 'SnackBar',

  props: {
    success: {
      type: Boolean,
      required: false,
      default: () => { return false }
    },
    error: {
      type: Boolean,
      required: false,
      default: () => { return false }
    }
  },

  data () {
    return {
      snackbar: false
    }
  },

  computed: {
    color () {
      if (this.success) {
        return 'success'
      }
      if (this.error) {
        return 'error'
      }
      return 'primary'
    }
  },

  methods: {
    open () {
      this.snackbar = true
    }
  }
}
</script>
